import React from 'react';
import ImageSlider from './ImageSlider';
import cabanaFamiliar from '../../images/Cabañas/CabañaFamiliar.png';
import cabanaDoble from '../../images/Cabañas/CabañaDoble.png';
import cabanaDobleDeluxe from '../../images/Cabañas/CabañaDobleDeluxe.png';
import interiorCabañaFamiliar2 from '../../images/CabanaInterior/InteriorCabanaPreliminar1.png';
import interiorCabañaFamiliar3 from '../../images/CabanaInterior/InteriorCabanaPreliminar2.png';
import interiorCabañaFamiliar1 from '../../images/CabanaInterior/InteriorCabanaPreliminar3.png';
import interiorCabañaFamiliar4 from '../../images/CabanaInterior/InteriorCabanaPreliminar4.png';
import interiorCabañaFamiliar5 from '../../images/CabanaInterior/InteriorCabanaPreliminar5.jpg';
import interiorCabañaFamiliar6 from '../../images/CabanaInterior/InteriorCabanaPreliminar15.jpg';

import interiorCabañaDoble1 from '../../images/CabanaInterior/InteriorCabanaPreliminar6.jpg';
import interiorCabañaDoble2 from '../../images/CabanaInterior/InteriorCabanaPreliminar7.jpg';
import interiorCabañaDoble3 from '../../images/CabanaInterior/InteriorCabanaPreliminar8.JPG';
import interiorCabañaDoble4 from '../../images/CabanaInterior/InteriorCabanaPreliminar9.jpg';
import interiorCabañaDoble5 from '../../images/CabanaInterior/InteriorCabanaPreliminar16.jpg';
import interiorCabañaDoble6 from '../../images/CabanaInterior/InteriorCabanaPreliminar17.jpg';

import interiorCabañaDobleDeluxe1 from '../../images/CabanaInterior/InteriorCabanaPreliminar11.jpg';
import interiorCabañaDobleDeluxe2 from '../../images/CabanaInterior/InteriorCabanaPreliminar12.jpg';
import interiorCabañaDobleDeluxe3 from '../../images/CabanaInterior/InteriorCabanaPreliminar13.jpg';
import interiorCabañaDobleDeluxe4 from '../../images/CabanaInterior/InteriorCabanaPreliminar14.jpg';
import interiorCabañaDobleDeluxe5 from '../../images/CabanaInterior/InteriorCabanaPreliminar18.jpg';
import interiorCabañaDobleDeluxe6 from '../../images/CabanaInterior/InteriorCabanaPreliminar19.jpg';


import Calendar from '../Calendar/Calendar';
import TipoCabana from '../Card/TiposCabañas'

import './css/CardCabañas.css';

const CardCabañas = ({ Tipo }) => {
    let imagenTipo;
    let imagenInterior1;
    let imagenInterior2;
    let imagenInterior3;
    let imagenInterior4;
    let imagenInterior5;
    let imagenInterior6;

    let Descripcion;
    let TipoCabaña;
    let Contenido = [];
    switch (Tipo) {
        case 'CabañaFamiliar':
            TipoCabaña = 'Cabaña Familiar';
            imagenInterior1 = interiorCabañaFamiliar1;
            imagenInterior2 = interiorCabañaFamiliar2;
            imagenInterior3 = interiorCabañaFamiliar3;
            imagenInterior4 = interiorCabañaFamiliar4;
            imagenInterior5 = interiorCabañaFamiliar5;
            imagenInterior6 = interiorCabañaFamiliar6;


            imagenTipo = cabanaFamiliar;
            Descripcion = 'Cabañas para 4 personas con dormitorio matrimonial con cama King y otro dormitorio con una cama de plaza y media y un sofá cama de una plaza y media en el living.';
            Contenido = ['Cama king', 'Pequeña Cocina', 'Frigobar', 'Aire Acondicionado', 'Calefacción', 'Toallas', 'Baño Privado', 'Televisión Satelital', 'Terraza Privada', 'Zona de Wifi(Starlink)', 'Segundo dormitorio con una cama de plaza y media', 'Sofá cama en el living'];
            break
        case 'CabañaDoble':
            TipoCabaña = 'Cabaña Matrimonial';
            imagenInterior1 = interiorCabañaDoble1;
            imagenInterior2 = interiorCabañaDoble2;
            imagenInterior3 = interiorCabañaDoble3;
            imagenInterior4 = interiorCabañaDoble4;
            imagenInterior5 = interiorCabañaDoble5
            imagenInterior6 = interiorCabañaDoble6



            imagenTipo = cabanaDoble;
            Descripcion = 'Cabaña para 2 personas con dormitorio matrimonial cama King.';
            Contenido = ['Cama king', 'Frigobar', 'Aire Acondicionado', 'Calefacción', 'Toallas', 'Baño Privado', 'Televisión Satelital', 'Terraza Privada', 'Zona de Wifi(Starlink)'];
            break
        default:
            TipoCabaña = 'Cabaña Matrimonial Superior';
            imagenInterior1 = interiorCabañaFamiliar3;
            imagenInterior2 = interiorCabañaDobleDeluxe2;
            imagenInterior3 = interiorCabañaDobleDeluxe3;
            imagenInterior4 = interiorCabañaDobleDeluxe4;
            imagenInterior5 = interiorCabañaDobleDeluxe5;
            imagenInterior6 = interiorCabañaDobleDeluxe6;


            imagenTipo = cabanaDobleDeluxe;
            Descripcion = 'Cabaña para 2 personas con dormitorio matrimonial cama King.';
            Contenido = ['Cama king', 'Frigobar', 'Aire Acondicionado', 'Calefacción', 'Toallas', 'Baño Privado', 'Televisión Satelital', 'Terraza Privada', 'Zona de Wifi(Starlink)', 'Dos ambientes'];
    }
    const elementosColumna1 = Contenido
    const elementosColumna2 = Contenido.slice(7);
    return (
        <div className="CardCabañas">

            <div className='CardCabanasDescripcion'>
                <ImageSlider tipo={Tipo} />
                <Calendar />
            </div>
            <div className='InformacionCabañas' data-aos="fade-left">
                <div className='BotonVolverCabañas'>
                    <a href="/Cabañas">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="arrow-left">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                        <p className='Volver' >Volver</p>
                    </a>
                </div>
                <div className='TituloCabañas'>
                    <p className='Titulo'>{TipoCabaña}</p>
                    <p className='Descripcion'>{Descripcion}</p>
                </div>

                <div className='ContenedorImagenes' data-aos="fade-up">
                    <div className='FilasImagenes'>
                        <div className='Box'>

                            <img src={imagenInterior1} alt="" />
                        </div>
                        <div className='Box'>

                            <img src={imagenInterior2} alt="" />

                        </div>
                    </div>
                    <div className='FilasImagenes'>
                        <div className='Box'>

                            <img src={imagenInterior3} alt="" />

                        </div>
                        <div className='Box'>
                            <img src={imagenInterior4} alt="" />
                        </div>
                    </div>
                    <div className='FilasImagenes'>
                        <div className='Box'>

                            <img src={imagenInterior5} alt="" />

                        </div>
                        <div className='Box'>
                            <img src={imagenInterior6} alt="" />
                        </div>
                    </div>

                    
                    <div className='ContenidoCabañas' data-aos="fade-up">
                        <p className='Titulo py-4'>CONTENIDO DE LA HABITACIÓN</p>
                        <div className='Columnas'>
                            <div className='Columna'>
                                {elementosColumna1.map((elemento, index) => (
                                    <li key={index}>{elemento}</li>
                                ))}
                            </div>
                            
                        </div>



                    </div>
                </div>
            </div>
        </div>

    );
};

export default CardCabañas;
