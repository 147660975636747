import React from 'react';
import './css/WelcomeDescription.css'; // Asegúrate de tener este archivo CSS en la misma carpeta

const WelcomeDescription = () => {
  return (
    <div className="welcome-description">
      <div className="column-welcome">
        <div className="text-row">
          <div className="Titulo-1-Inicio" style={{ textAlign: 'center' }}>
          {/* REFUGIO MISTERIOS DEL ELQUI */}
          </div>
          <div className="Titulo-2-Inicio font-bold" style={{ textAlign: 'center' }}>
            Donde la magia del valle se convierte en tu refugio
          </div>
        </div>
      </div>
      <div className="column-welcome">
        <div className="text-row">
          <div className="Texto-Bienvenida" style={{ textAlign: 'center' }}>
            <p>
              Bienvenidos a nuestro oasis en el corazón del Valle del Elqui.
              Disfruta de una estancia inolvidable rodeado de paisajes impresionantes, instalaciones de lujo y una hospitalidad excepcional.
            </p>
          </div>
        </div>
        <div className="text-row">
          <div className="Texto-Bienvenida" style={{ textAlign: 'center' }}>
            Relájate en nuestra piscina, revitalízate en el spa, contempla las estrellas desde nuestro observatorio y descubre la magia de Pisco Elqui con nuestras actividades al aire libre.
          </div>
        </div>
        <div className="text-row">
          <div className="Texto-Bienvenida" style={{ textAlign: 'center' }}>
            Dudas y consultas a <a href="mailto:reservas@misteriosdelelqui.cl">reservas@misteriosdelelqui.cl</a>
          </div>
        </div>
      </div>
    </div>
  );
};


export default WelcomeDescription;